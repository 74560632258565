import request from "./config/request"

const create = async (id, professional_data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/create/${id}`,
        data: professional_data,
        vtAuth: true,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}
const deleteDocuments = async id => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/delete-documents/${id}`,
        vtAuth: true,
    })
}
const downloadProfessionalDocument = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-documents/${id}/downloadFile`,
        vtAuth: true,
    })
}
export { create, deleteDocuments, downloadProfessionalDocument }
