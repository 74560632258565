import React, { useState, useRef } from "react"
import Select from "react-select"
import {
    Row,
    Col,
    Modal,
    Label,
    Button,
    Input,
    CardBody,
    FormGroup,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Formik, Field, Form, FastField } from "formik"
import NumberFormat from "react-number-format"
import { useDispatch, useSelector } from "react-redux"
import AsyncSelect from "react-select/async"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import debounce from "debounce-promise"
import {
    create,
    update,
    deleteItemExecComission,
} from "services/vitta-core/item-exec-comissions"

const AsyncSelectInputItem = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)

            if (field?.name === "item_id") {
                setFieldValue("price", option?.price ? option?.price / 100 : 0)
            }
        }}
    />
)
const SelectInputt = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
            if (field?.name == "unit_id") {
                props.refreshOptions(
                    option?.value,
                    props?.valuesForm,
                    setFieldValue
                )
            }
        }}
        ref={props.Ref}
    />
)

const SelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    options,
    ...props
}) => {
    const selectedOption = options.find(e => e.value == props.value);
    return (
        <Select
            {...field}
            value={selectedOption} 
            options={options}
            isDisabled={props.isDisabled}
            onChange={option => {
                setFieldValue(field.name, option.value);
            }}
        />
    );
}
const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)
const ModalExecutionComissions = ({ show, data, onClose, execModeOption }) => {
    const Scope = useSelector(state => state.scope)
    const inputUnit = useRef()

    const dispatch = useDispatch()
   
    const defaultItemValues = {
        item_id: "",
        price: 0,
        professional_exec_id: "",
        comission_fix: 0,
        comission_percent: 0,
        execution_mode:1,
        unit_id: { label: Scope?.unit?.name, value: Scope?.unit?.id },
    }
   
    const [pageData, setPageData] = useState(defaultItemValues)

    const onSubmit = async values => {
        try {
            const dataSubmit = {
                comission_fix: values?.comission_fix * 100,
                comission_percent: values?.comission_percent * 100,
                item_id: values?.item_id?.value || values?.item?.value,
                unit_id: values?.unit_id?.value,
                professional_exec_id: values?.professional_exec_id?.value,
                execution_mode:values.execution_mode
            }

            if (!dataSubmit.item_id) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Preencha o serviço!",
                        type: "error",
                    })
                )
                return
            }
            if (
                dataSubmit.comission_percent == 0 &&
                dataSubmit.comission_fix == 0
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Tem certeza? ",
                        text: "As duas comissões estão zeradas!",
                        type: "warning",
                        onConfirmAction: () => {
                            handleConfirmation(dataSubmit)
                        },
                        showCancel: true,
                        showConfirm: true,
                        cancelBtnText: "Não",
                        confirmBtnText: "Confirmar",
                    })
                )
                return
            }
            if (values?.rowComissionId) {
                await update(values.rowComissionId, dataSubmit)
            } else {
                await create(dataSubmit)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Salvo!",
                    type: "success",
                })
            )
            onClose({ register: true })

            setPageData(defaultItemValues)
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "item_exec_comission_has_created":
                        textError =
                            "Já existe uma regra de comissão para esta unidade e serviço."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const handleConfirmation = async data => {
        try {
            if (data.rowComissionId) {
                await update(data.rowComissionId, data)
            } else {
                await create(data)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Salvo!",
                    type: "success",
                })
            )

            onClose({ register: true })

            setPageData(defaultItemValues)
        } catch (e) {
            console.log(e)
            if (e?.response?.status === 500 || 400) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text: "Já existe esse registro!",
                        type: "error",
                    })
                )
            }
        }
    }
    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let unit_id_control =
                    inputUnit?.current?.select?.state?.selectValue[0]?.value
                unit_id_control == null
                    ? (unit_id_control = Scope?.unit?.id)
                    : ""

                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await itemServiceSearch(
                    {
                        term: inputValue,
                        unit_id: unit_id_control,
                        status: statusActive,
                    },
                    1,
                    20
                )

                let items_values = []

                response.data.forEach(element => {
                    items_values.push({
                        label: element?.name,
                        value: element?.id,
                        price: element?.price_table_item?.price,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceItems = debounce(loadItemsInputOptions, 600)

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await professionalServiceSearch(
                    { term: inputValue },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Todos Profissionais",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )
    const refreshOptions = async (unit_id, values, setFieldValue) => {
        try {
            let unit_id_control = unit_id
            unit_id_control =
                unit_id_control == null ? Scope?.unit?.id : unit_id_control

            let result = null

            if (unit_id_control && values?.item_id?.value) {
                result = await itemServiceSearch({
                    unit_id: unit_id_control,
                    item_id: values?.item_id?.value,
                })
            }

            const itemDb = result?.data[0]
            const item = values?.item_id

            if (
                item?.value === itemDb?.id &&
                itemDb?.price_table_item?.price >= 0
            ) {
                setFieldValue(`price`, itemDb?.price_table_item?.price / 100)
            } else {
                setFieldValue(`price`, 0)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    confirmDeleteRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Excluir !",
            })
        )
    }

    const confirmDeleteRecord = async id => {
        try {
            await deleteItemExecComission(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro Excluído com Sucesso!",
                    type: "success",
                })
            )
            onClose({ register: true })
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    return (
        <Modal
            isOpen={show}
            // toggle={() => { toggleModal() }}
            centered={false}
            size="lg"
        >
            <CardBody>
                <Formik
                    initialValues={data?.dataEdit ? data.dataEdit : pageData}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({ values, defaultItemValues }) => (
                        <Form>
                            <div className="modal-header">
                                <div className="modal-title " id="myModalLabel">
                                    <h5>
                                        {values?.rowComissionId
                                            ? "Alterar Regra de Comissão "
                                            : "Adicionar Regra de Comissão "}

                                        {data?.dataEdit?.rowComissionId &&
                                        data?.dataEdit?.professional_exec_id
                                            ?.value != null ? (
                                            <Button
                                                color="danger"
                                                type="button"
                                                className="ml-5"
                                                onClick={() => {
                                                    deleteRecord(
                                                        values?.rowComissionId
                                                    )
                                                }}
                                            >
                                                Excluir Regra{" "}
                                                <i className="fas fa-trash-alt fa-lg"></i>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </h5>
                                </div>

                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row className="mb-2 ">
                                    {values.rowComissionId ? (
                                        <Col sm="2">
                                            <FormGroup>
                                                <Label className="">
                                                    {" "}
                                                    Regra #
                                                </Label>
                                                <Input
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control bg-light"
                                                    defaultValue={
                                                        values.rowComissionId
                                                    }
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                    ) : null}
                                    <Col
                                        sm={
                                            data?.dataEdit?.unit ||
                                            data?.dataEdit?.unit ||
                                            data?.dataEdit?.professional_exec_id
                                                ? 4
                                                : 6
                                        }
                                    >
                                        <FormGroup>
                                            <Label className=""> Unidade</Label>
                                            <Field
                                                name="unit_id"
                                                placeholder="Selecione a unidade"
                                                defaultValue={values?.unit}
                                                Ref={inputUnit}
                                                isDisabled={
                                                    data?.dataEdit?.item ||
                                                    data?.dataEdit?.unit
                                                        ? true
                                                        : false
                                                }
                                                refreshOptions={refreshOptions}
                                                valuesForm={values}
                                                component={SelectInputt}
                                                options={data?.unitsOptions}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="">
                                                {" "}
                                                Profissional
                                            </Label>
                                            <Field
                                                name="professional_exec_id"
                                                placeholder="Selecione o Professional..."
                                                defaultValue={
                                                    values?.professional_exec_id
                                                }
                                                isDisabled={
                                                    data?.dataEdit
                                                        ?.professional_exec_id
                                                        ? true
                                                        : false
                                                }
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={
                                                    debounceOptionsProfessional
                                                }
                                                component={AsyncSelectInputItem}
                                                noOptionsMessage={() =>
                                                    "Sem Resultados. Digite para Buscar"
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Label className="">Serviço</Label>
                                            <Field
                                                name="item_id"
                                                placeholder="Selecione o Item..."
                                                defaultOptions
                                                isClearable
                                                cacheOptions
                                                loadOptions={debounceItems}
                                                component={AsyncSelectInputItem}
                                                defaultValue={values?.item}
                                                isDisabled={
                                                    data?.dataEdit?.item
                                                        ? true
                                                        : false
                                                }
                                                noOptionsMessage={() =>
                                                    "Sem Resultados. Digite para Buscar"
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label> Preço de tabela</Label>
                                            <FastField
                                                name="price"
                                                disabled={true}
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                prefix={"R$ "}
                                                className="form-control bg-light"
                                                component={CurrencyInput}
                                                value={values?.price}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                    <FormGroup>
                                            <Label>Modo de Execução</Label>
                                            <Field
                                                name="execution_mode"
                                                value={values?.execution_mode}
                                                options={
                                                    execModeOption
                                                  }
                                                isDisabled={
                                                    data?.dataEdit? true : false
                                                }
                                                component={SelectInput}
                                                />  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="">
                                                Comissão Fixa
                                            </Label>
                                            <FastField
                                                name="comission_fix"
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                prefix={"R$ "}
                                                className="form-control"
                                                component={CurrencyInput}
                                                value={values?.comission_fix}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label className="">
                                                Comissão Variável
                                            </Label>
                                            <FastField
                                                name="comission_percent"
                                                defaultValue={
                                                    values?.comission_percent
                                                }
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                suffix={" %"}
                                                className="form-control"
                                                component={CurrencyInput}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>

                            <div className="modal-footer">
                                <div>
                                    <Button
                                        type="submit"
                                        className="btn btn-info"
                                    >
                                        {"Salvar"}
                                    </Button>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Modal>
    )
}

export default ModalExecutionComissions
