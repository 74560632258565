import React from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"

import { Formik, Form, FastField } from "formik"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { changeDateExecItem } from "services/vitta-core/sales-order"
const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const HandleDataExecItem = ({ show, data, onClose }) => {
    const dispatch = useDispatch()

    const defaultValues = {
        date_change: "",
    }

    const confirmChangeData = data_form => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    onSubmit(data_form)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const onSubmit = async data_form => {
        try {
            if (!data_form?.date_change) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Preencha o campo de data!",
                        type: "error",
                    })
                )
                return
            }

            let dataSend = {
                date_change: data_form?.date_change,
            }
            await changeDateExecItem(data?.id, dataSend)
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            onClose(true)
        } catch (e) {
            console.log(e)
            if (e.response.data.error_code === "executed_item") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse item já foi executado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    return (
        <Modal
            isOpen={show}
            centered={true}
            // toggle={onCloseModal}
        >
            <Formik
                initialValues={defaultValues}
                enableReinitialize={true}
                onSubmit={confirmChangeData}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Alterar data de execução - Item #{data?.id}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="12">
                                    <Label>Nova data de execução</Label>
                                    <FastField
                                        name={`date_change`}
                                        defaultValue={values.date_change}
                                        component={DateInput}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default HandleDataExecItem
