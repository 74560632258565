import React, { useEffect, useState, useRef } from "react"
import { reportOdontologicData } from "services/vitta-core/assessments"
import SALES_DOCS_TYPE from "utils/consts/sales-docs-type"
import logovitta from "assets/images/logovitta2.jpg"
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap"
import arcada from "assets/images/arcada.png"
import ReactToPrint from "react-to-print"
import "./styles.css"
const printOdontologicData = () => {
    const [dataReport, setDataReport] = useState({})
    const componentRef = useRef()
    const elementsRef = useRef({})
    const loadData = async () => {
        try {
            let searchLoc = location.search
            const params = new URLSearchParams(searchLoc)
            const entity_type = params.get("entity_type")
            const entity_id = params.get("entity_id")
            const assessment_id = params.get("assessment_id")
            let filter = {
                entity_id: entity_id,
                entity_type: entity_type,
                assessment_id: assessment_id,
            }

            let dataReport = await reportOdontologicData(filter)
            let data = dataReport?.data
            let dataRender = {
                assessment_data: {
                    id: data?.id,
                    created_at: data?.created_at,
                },
                patient: data?.patient,
                options: [],
                type_doc: entity_type,
            }
            let entityItems = []
            if (entity_type == SALES_DOCS_TYPE.SALES_ORDER) {
                for (let i = 0; i < data?.sales_orders?.length; i++) {
                    let order = data.sales_orders[i]
                    let totalOption = 0
                    let dataToPush = []
                    for (let i = 0; i < order?.items?.length; i++) {
                        let useItem = order?.items[i]
                        totalOption += useItem?.quantity * useItem?.price
                        let tamVt = useItem?.sales_order_item_stage?.length
                        if (
                            useItem?.sales_order_item_stage?.length <= 0 &&
                            useItem?.sales_order_item_stage?.length <= 0
                        ) {
                            tamVt = 1
                        }
                        for (let j = 0; j < tamVt; j++) {
                            let useStage = useItem?.sales_order_item_stage[j]
                            let objToPush = {
                                sales_order_id: useItem?.doc_id,
                                sales_order_item_id: useItem?.id,
                                item_id: useItem?.item?.id,
                                item_name: useItem?.item?.name,
                                quantity: useItem?.quantity,
                                price: useStage?.percent_value
                                    ? Math.trunc(
                                          useItem?.price *
                                              useItem?.quantity *
                                              (useStage?.percent_value / 10000)
                                      )
                                    : Math.trunc(
                                          useItem?.price * useItem?.quantity
                                      ),
                                price_table: useStage?.percent_value
                                    ? Math.trunc(
                                          useItem?.price_table *
                                              useItem?.quantity *
                                              (useStage?.percent_value / 10000)
                                      )
                                    : Math.trunc(
                                          useItem?.price_table *
                                              useItem?.quantity
                                      ),
                                price_table_id: useItem?.price_table_id,
                                price_lab: useItem?.price_lab,
                                stage_id: useStage?.stage_id,
                                stage_name: useStage?.procedure_stage?.name,
                                percent_stage_value: useStage?.percent_value,
                                stage_sequence: useStage?.stage_sequence,
                                tooth_number: useItem?.tooth_number
                            }
                            dataToPush.push(objToPush)
                        }
                    }
                    entityItems.push({
                        entity_id: order?.id,
                        totalOption: totalOption,
                        items: dataToPush,
                    })
                }
            } else {
                for (let i = 0; i < data?.sales_quotes?.length; i++) {
                    let quote = data.sales_quotes[i]
                    let totalOption = 0
                    let dataToPush = []
                    for (let i = 0; i < quote?.items?.length; i++) {
                        let useItem = quote?.items[i]
                        totalOption += useItem?.quantity * useItem?.price
                        let tamVt = useItem?.sales_quote_item_stage?.length
                        if (
                            useItem?.sales_quote_item_stage?.length <= 0 &&
                            useItem?.sales_quote_item_stage?.length <= 0
                        ) {
                            tamVt = 1
                        }
                        for (let j = 0; j < tamVt; j++) {
                            let useStage = useItem?.sales_quote_item_stage[j]
                            let objToPush = {
                                sales_order_id: useItem?.doc_id,
                                sales_order_item_id: useItem?.id,
                                item_id: useItem?.item?.id,
                                item_name: useItem?.item?.name,
                                quantity: useItem?.quantity,
                                price: useStage?.percent_value
                                    ? Math.trunc(
                                          useItem?.price *
                                              useItem?.quantity *
                                              (useStage?.percent_value / 10000)
                                      )
                                    : Math.trunc(
                                          useItem?.price * useItem?.quantity
                                      ),
                                price_table: useStage?.percent_value
                                    ? Math.trunc(
                                          useItem?.price_table *
                                              useItem?.quantity *
                                              (useStage?.percent_value / 10000)
                                      )
                                    : Math.trunc(
                                          useItem?.price_table *
                                              useItem?.quantity
                                      ),
                                price_table_id: useItem?.price_table_id,
                                price_lab: useItem?.price_lab,
                                stage_id: useStage?.stage_id,
                                stage_name: useStage?.procedure_stage?.name,
                                percent_stage_value: useStage?.percent_value,
                                stage_sequence: useStage?.stage_sequence,
                                tooth_number: useItem?.tooth_number,
                            }
                            dataToPush.push(objToPush)
                        }
                    }
                    entityItems.push({
                        entity_id: quote?.id,
                        totalOption: totalOption,
                        items: dataToPush,
                    })
                }
            }
            dataRender.options = entityItems
            setDataReport(dataRender)
        } catch (e) {
            console.log(e)
        }
    }

    function formatDateToDDMMYYYY(date) {
        const dateObj = new Date(date)

        const day = String(dateObj.getDate()).padStart(2, "0")
        const month = String(dateObj.getMonth() + 1).padStart(2, "0") // Note: month is zero-based, so we add 1 to get the correct month
        const year = dateObj.getFullYear()

        return `${day}/${month}/${year}`
    }
    const onPrint = () => {
        let pages = [
            `
            <div  className="page-content">
            <Card>
                <CardBody>
                  
                    <div className="header-print mb-5">
                    <Row>
                        <Col align="start">
                            {" "}
                            <img
                                src={logovitta}
                                alt=""
                                height="100px"
                                className="mb-3"
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col sm="8">
                            <div style="display:"flex";flexDirection:"row";flexFlow:"wrap";gap:"8px";fontSize:"13px";>
                                <strong >Nome do Paciente:</strong>{" "}
                                Jorginho atáides teste
                                <small>
                                    {" "}
                                    (CPF: 70185521177)
                                </small>
                            </div>
                            <div className="mt-1"></div>
                            <div style={{display:"flex",flexDirection:"row",flexFlow:"wrap",gap:"8px",fontSize:"13px"}}>
                                <strong  >Código do Paciente:</strong>{" "}
                                2
                            </div>
                            <div className="mt-1"></div>
                            <div style={{display:"flex",flexDirection:"row",flexFlow:"wrap",gap:"8px",fontSize:"13px"}}>
                                <strong >Telefone(s):</strong>{" "}
                             6235678718
                            </div>
                        </Col>
                        <Col sm="4" align="end">
                            {" "}
                            <div style={{display:"flex",flexDirection:"row",flexFlow:"wrap",gap:"8px",justifyContent:"end",fontSize:"13px"}}>
                                <strong >ID da Avaliação:</strong>{" "}
                               2
                            </div>
                            <div className="mt-1"></div>
                            <div style={{display:"flex",flexDirection:"row",flexFlow:"wrap",gap:"8px",justifyContent:"end",fontSize:"13px"}}>
                                <strong>Data da Avaliação:</strong>{" "}
                              20/08/2020
                            </div>
                            <div className="mt-1"></div>
                            <div style={{display:"flex",flexDirection:"row",flexFlow:"wrap",gap:"8px",justifyContent:"end",fontSize:"13px"}}>
                                <strong style={{fontSize:"13px"}}>Tipo documento:</strong>{" "}
                              Pedido de venda
                            </div>
                        </Col>
                    </Row>
                    </div>
                    <hr />
                    <div className="div-option-print">
                   
                        <div >
                            <Row>
                                <Col>
                                    <div style={{ backgroundColor: "#0195d5",marginBottom:"10px",color:"white",fontWeight:"bold",borderRadius:"3px" }}>
                                        {" "}
                                        <CardBody style={{ padding: "7px" }}>
                                            <strong>Orçamento</strong>
                                        </CardBody>{" "}
                                    </div>
                                    <Row>
                                        <Col align="center">
                                            {" "}
                                            <img
                                                src={arcada}
                                                alt=""
                                                className="mb-3"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table bordered className="table-print">
                                        <thead>
                                            <tr>
                                                <th>Procedimento</th>
                                                <th>Fase</th>
                                                <th>Dente</th>
                                                <th>Qtde.</th>
                                                <th>Valor Unitário (R$)</th>
                                                <th>Valor Total (R$)</th>
                                                <th>Valor Negociado (R$)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                    <tr
                                                    >
                                                        <td>
                                                          ITEM TESTE
                                                        </td>
                                                        <td>
                                                          FASE TESTE
                                                        </td>
                                                        <td>
                                                          2
                                                        </td>
                                                        <td>
                                                          1
                                                        </td>
                                                        <td>
                                                           2000
                                                        </td>
                                                        <td>
                                                          2000
                                                        </td>
                                                        <td>
                                                           2000
                                                        </td>
                                                    </tr>
                                        
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-3">
                                <Col align="end">
                                    {" "}
                                    <strong className="">
                                SALES_DOCS_TYPE.SALES_ORDER
                                   Pedido
                                    </strong>
                                    <strong className="text-info">
                                      2000
                                    </strong>
                                </Col>
                            </Row>
                      
                        </div>
                  
                    </div>
                    <Row style={{paddingLeft:"20px"}}>
                        <Col sm="13" className="mt-4">
                          <strong>Impresso em: {new Date().toLocaleDateString('pt-BR', {hour:"2-digit", hour13: false, minute:"2-digit", second:"2-digit"})}</strong>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div> `,
        ]
        let allPagesHtml = ""

        for (let i = 0; i < pages.length; i++) {
            allPagesHtml +=
                "<div style='page-break-after: always; page-break-inside: avoid; height: auto; width: auto; margin: 0; box-sizing: border-box; position: relative;'>" +
                pages[i] +
                "</div>"
        }

        let w = 1024
        let h = 768
        const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
        const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
        allPagesHtml +=
            '<script type="text/javascript">window.onload = function() { window.print(); }</script>'

        const winUrl = URL.createObjectURL(
            new Blob([allPagesHtml], { type: "text/html" })
        )

        return window.open(
            winUrl,
            "Documentos",
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, 
            resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        )
    }
    useEffect(() => {
        console.log("caiuaqqq")
        // Função para calcular a altura do elemento e ajustar o marginTop
        // const calcularAlturaEAdjustMarginTop = (key) => {
        //   if (elementsRef.current[key]) {
        //     const alturaDoElemento = elementsRef.current[key].clientHeight;
        //     const alturaFolhaA4 = 842; // Altura de uma folha A4 em pixels (por exemplo, 842px)
        //     const marginTop = alturaFolhaA4 - alturaDoElemento;
        //     elementsRef.current[key].style.marginTop = `${marginTop}px`;
        //   }
        // };

        // Object.keys(elementsRef.current).forEach((key) => {
        //   calcularAlturaEAdjustMarginTop(key);
        // });
    }, [elementsRef])
    const onLoadDiv = (id, index) => {
        const divElement = document.getElementById(id)
        if (divElement) {
            const alturaDaDiv = divElement.clientHeight

            //  const alturaFolhaA4 = 842;
            // const marginTop = alturaFolhaA4 - alturaDaDiv;
            if (index > 0) {
                // console.log("alturadiv",alturaDaDiv)
                // divElement.style.marginTop = `${ alturaDaDiv < 400 ? 500 : 400}px`;
                console.log("alt", alturaDaDiv)
                console.log(alturaDaDiv < 400 ? "mt-500" : "mt-400")
                divElement.className = `${
                    alturaDaDiv < 400 ? "mt-500" : "mt-400"
                }`
            }
        }
    }
    useEffect(() => {
        loadData()
    }, [])

    return (
        <div className="page-content" ref={componentRef} size="A4">
            <Card>
                <CardBody className="body-print">
                    {/* <button type="button"onClick={()=>{onPrint()}}>teste</button> */}
                    <div className="header-print mb-5">
                        <Row>
                            <Col align="start">
                                {" "}
                                <img
                                    src={logovitta}
                                    alt=""
                                    height="100px"
                                    className="mb-3"
                                />
                            </Col>
                            <Col align="end">
                                <strong className="d-none d-print-block text-info">
                                    (62) 3996-0505{" "}
                                    <i className="fas fa-phone"></i>
                                </strong>
                                <ReactToPrint
                                    trigger={() => (
                                        <Button className="btn btn-info d-print-none">
                                            <i className="fas fa-print text-white mr-2"></i>{" "}
                                            Imprimir
                                        </Button>
                                    )}
                                    content={() => componentRef.current} // Use o ref como content
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="8">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>Nome do Paciente:</strong>{" "}
                                    {dataReport?.patient?.name}{" "}
                                    <small>
                                        {" "}
                                        (CPF: {dataReport?.patient?.cpf})
                                    </small>
                                </div>
                                <div className="mt-1"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>Data de Nascimento:</strong>{" "}
                                    {dataReport?.patient?.birth_date
                                        ? dataReport?.patient?.birth_date.substr(
                                              8,
                                              2
                                          ) +
                                          "/" +
                                          dataReport?.patient?.birth_date.substr(
                                              5,
                                              2
                                          ) +
                                          "/" +
                                          dataReport?.patient?.birth_date.substr(
                                              0,
                                              4
                                          )
                                        : ""}
                                </div>
                                <div className="mt-1"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>Telefone(s):</strong>{" "}
                                    {dataReport?.patient?.mobile_phone &&
                                    dataReport?.patient?.phone
                                        ? dataReport?.patient?.mobile_phone +
                                          " / " +
                                          dataReport?.patient?.phone
                                        : dataReport?.patient?.phone
                                        ? dataReport?.patient?.phone
                                        : dataReport?.patient?.mobile_phone
                                        ? dataReport?.patient?.mobile_phone
                                        : null}
                                </div>
                                <div className="mt-1"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>Código do Paciente:</strong>{" "}
                                    {dataReport?.patient?.id}
                                </div>
                            </Col>
                            <Col sm="4" align="end">
                                {" "}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        justifyContent: "end",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>ID da Avaliação:</strong>{" "}
                                    {dataReport?.assessment_data?.id}
                                </div>
                                <div className="mt-1"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        justifyContent: "end",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong>Data da Avaliação:</strong>{" "}
                                    {formatDateToDDMMYYYY(
                                        dataReport?.assessment_data?.created_at
                                    )}
                                </div>
                                <div className="mt-1"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexFlow: "wrap",
                                        gap: "8px",
                                        justifyContent: "end",
                                        fontSize: "13px",
                                    }}
                                >
                                    <strong style={{ fontSize: "13px" }}>
                                        Tipo documento:
                                    </strong>{" "}
                                    {dataReport?.type_doc ==
                                    SALES_DOCS_TYPE.SALES_ORDER
                                        ? "Pedido de Venda"
                                        : dataReport?.type_doc ==
                                          SALES_DOCS_TYPE.SALES_QUOTE
                                        ? "Orçamento"
                                        : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="div-option-print">
                        {dataReport?.options?.map((item, index) => (
                            <div
                                key={"reportp" + index}
                                id={"divOption" + index}
                                // ref={(el) => (elementsRef.current[item] = el)}
                                onLoad={e => {
                                    let id = "divOption" + index
                                    onLoadDiv(id, index)
                                }}
                            >
                                {index > 0 ? (
                                    <div
                                        className="header-print d-none d-print-block"
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <Row>
                                            <Col align="start">
                                                {" "}
                                                <img
                                                    src={logovitta}
                                                    alt=""
                                                    height="100px"
                                                    className="mb-3"
                                                />
                                            </Col>
                                            <Col align="end">
                                                <strong className="d-none d-print-block text-info">
                                                    (62) 3996-0505{" "}
                                                    <i className="fas fa-phone"></i>
                                                </strong>
                                                <ReactToPrint
                                                    trigger={() => (
                                                        <Button className="btn btn-info d-print-none">
                                                            <i className="fas fa-print text-white mr-2"></i>{" "}
                                                            Imprimir
                                                        </Button>
                                                    )}
                                                    content={() =>
                                                        componentRef.current
                                                    } // Use o ref como content
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="8">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong>
                                                        Nome do Paciente:
                                                    </strong>{" "}
                                                    {dataReport?.patient?.name}{" "}
                                                    <small>
                                                        {" "}
                                                        (CPF:{" "}
                                                        {
                                                            dataReport?.patient
                                                                ?.cpf
                                                        }
                                                        )
                                                    </small>
                                                </div>
                                                <div className="mt-1"></div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong>
                                                        Código do Paciente:
                                                    </strong>{" "}
                                                    {dataReport?.patient?.id}
                                                </div>
                                                <div className="mt-1"></div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong>
                                                        Telefone(s):
                                                    </strong>{" "}
                                                    {dataReport?.patient
                                                        ?.mobile_phone &&
                                                    dataReport?.patient?.phone
                                                        ? dataReport?.patient
                                                              ?.mobile_phone +
                                                          " / " +
                                                          dataReport?.patient
                                                              ?.phone
                                                        : dataReport?.patient
                                                              ?.phone
                                                        ? dataReport?.patient
                                                              ?.phone
                                                        : dataReport?.patient
                                                              ?.mobile_phone
                                                        ? dataReport?.patient
                                                              ?.mobile_phone
                                                        : null}
                                                </div>
                                            </Col>
                                            <Col sm="4" align="end">
                                                {" "}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        justifyContent: "end",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong>
                                                        ID da Avaliação:
                                                    </strong>{" "}
                                                    {
                                                        dataReport
                                                            ?.assessment_data
                                                            ?.id
                                                    }
                                                </div>
                                                <div className="mt-1"></div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        justifyContent: "end",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong>
                                                        Data da Avaliação:
                                                    </strong>{" "}
                                                    {formatDateToDDMMYYYY(
                                                        dataReport
                                                            ?.assessment_data
                                                            ?.created_at
                                                    )}
                                                </div>
                                                <div className="mt-1"></div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexFlow: "wrap",
                                                        gap: "8px",
                                                        justifyContent: "end",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <strong
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        Tipo documento:
                                                    </strong>{" "}
                                                    {dataReport?.type_doc ==
                                                    SALES_DOCS_TYPE.SALES_ORDER
                                                        ? "Pedido de Venda"
                                                        : dataReport?.type_doc ==
                                                          SALES_DOCS_TYPE.SALES_QUOTE
                                                        ? "Orçamento"
                                                        : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                ) : null}

                                <Row>
                                    <Col>
                                        <div
                                            style={{
                                                backgroundColor: "#0195d5",
                                                marginBottom: "10px",
                                                color: "white",
                                                fontWeight: "bold",
                                                borderRadius: "3px",
                                            }}
                                        >
                                            {" "}
                                            <CardBody
                                                style={{ padding: "7px" }}
                                            >
                                                <strong>
                                                    {" "}
                                                    {dataReport?.type_doc ==
                                                    SALES_DOCS_TYPE.SALES_ORDER
                                                        ? "Pedido"
                                                        : dataReport?.type_doc ==
                                                          SALES_DOCS_TYPE.SALES_QUOTE
                                                        ? "Orçamento"
                                                        : null}{" "}
                                                    {index + 1}
                                                </strong>
                                            </CardBody>{" "}
                                        </div>
                                        <Row>
                                            <Col align="center">
                                                {" "}
                                                <img
                                                    src={arcada}
                                                    alt=""
                                                    className="mb-3"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table bordered className="table-print">
                                            <thead>
                                                <tr>
                                                    <th>Procedimento</th>
                                                    <th>Fase</th>
                                                    <th>Dente</th>
                                                    <th>Qtde.</th>
                                                    <th>Valor Unitário (R$)</th>
                                                    <th>Valor Total (R$)</th>
                                                    <th>
                                                        Valor Negociado (R$)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item?.items?.map(
                                                    (
                                                        assessmentItem,
                                                        assessmentIndex
                                                    ) => (
                                                        <tr
                                                            key={
                                                                "tr" +
                                                                assessmentIndex
                                                            }
                                                        >
                                                            <td>
                                                                {
                                                                    assessmentItem?.item_name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    assessmentItem?.stage_name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    assessmentItem?.tooth_number
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    assessmentItem?.quantity
                                                                }
                                                            </td>
                                                            <td>
                                                                {"R$ " +
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(
                                                                        parseInt(
                                                                            assessmentItem?.price_table
                                                                        ) / 100
                                                                    )}{" "}
                                                            </td>
                                                            <td>
                                                                {"R$ " +
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(
                                                                        parseInt(
                                                                            assessmentItem?.price_table *
                                                                                assessmentItem?.quantity
                                                                        ) / 100
                                                                    )}{" "}
                                                            </td>
                                                            <td>
                                                                {"R$ " +
                                                                    new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(
                                                                        parseInt(
                                                                            assessmentItem?.price
                                                                        ) / 100
                                                                    )}{" "}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-3">
                                    <Col align="end">
                                        {" "}
                                        <strong className="">
                                            Total{" "}
                                            {dataReport?.type_doc ==
                                            SALES_DOCS_TYPE.SALES_ORDER
                                                ? "Pedido"
                                                : dataReport?.type_doc ==
                                                  SALES_DOCS_TYPE.SALES_QUOTE
                                                ? "Orçamento"
                                                : null}{" "}
                                            {index + 1}:{" "}
                                        </strong>
                                        <strong className="text-info">
                                            {" "}
                                            {"R$ " +
                                                new Intl.NumberFormat("pt-br", {
                                                    style: "decimal",
                                                    currency: "BRL",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }).format(
                                                    parseInt(
                                                        item?.totalOption
                                                    ) / 100
                                                )}{" "}
                                        </strong>
                                    </Col>
                                </Row>
                                {index + 1 < dataReport?.options?.length ? (
                                    <hr />
                                ) : null}
                                <Row style={{ paddingLeft: "20px" }}>
                                    <Col sm="13" className="mt-4">
                                        <strong>
                                            Impresso em:{" "}
                                            {new Date().toLocaleDateString(
                                                "pt-BR",
                                                {
                                                    hour: "2-digit",
                                                    hour13: false,
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                }
                                            )}
                                        </strong>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default printOdontologicData
