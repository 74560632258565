import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"
import {
    search as SalesDocsServiceSearch,
    cancelSalesDoc,
    genInvoice,
} from "services/vitta-core/sales-order"
import { cancelSalesInvoiceDoc } from "services/vitta-core/sales-invoice"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import ModalPayment from "pages/SalesDocs/Payment/index"
import { findUnit } from "services/vitta-core/unit"
import { findPatient } from "services/vitta-core/patient"
import SALES_ORDER_STATUS from "utils/consts/sales-orders-status"
import debounce from "debounce-promise"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const SalesOrders = () => {
    const [SalesDocsList, setSalesDocsList] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        custom: true,
        data: [],
    })

    const [modalPaymentData, setModalPaymentData] = useState({
        show: false,
        data: null,
    })

    const [pageControl, setPageControl] = useState({ searching: false })
    const [statusOrders, setStatusOrders] = useState([
        {
            label: "Todos",
            value: null,
        },
        {
            label: "Aguardando Pagamento",
            value: SALES_ORDER_STATUS.AWAITING_PAYMENT,
        },
        {
            label: "Parcialmente Pago",
            value: SALES_ORDER_STATUS.PARTIALLY_PAID,
        },
        {
            label: "Aguardando Aprovação",
            value: SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL,
        },
        {
            label: "Cancelado",
            value: SALES_ORDER_STATUS.CANCELED,
        },
        {
            label: "Não Aprovado",
            value: SALES_ORDER_STATUS.COMPANY_NOT_APPROVED,
        },
        {
            label: "Pago",
            value: SALES_ORDER_STATUS.PAID,
        },
        {
            label: "Notas Emitidas",
            value: SALES_ORDER_STATUS.INVOICE_ISSUED,
        },
        {
            label: "Aguardando Laboratório",
            value: SALES_ORDER_STATUS.AWAITING_LABORATORY,
        },
    ])

    const inputPatient = useRef()
    const inputStartCreatedAt = useRef()
    const inputEndCreatedAt = useRef()
    const inputStatus = useRef()
    const inputRefUnit = useRef()

    const dispatch = useDispatch()
    const history = useHistory()
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)

    const search = async (filter, page) => {
        // if (pageControl.searching) return
        let sizePerPage = 10
        setPageControl({ ...pageControl, ...{ searching: true } })
        try {
            //limpando data table
            setSalesDocsList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: 0,
                custom: true,
                data: [],
            })

            let data = await SalesDocsServiceSearch(filter, page, sizePerPage)

            setSalesDocsList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: data.total_pages * sizePerPage,
                custom: true,
                data: data.data,
            })
        } catch (e) {
            console.log("error", e)
        }
        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    useEffect(() => {
        let page = 1

        if (location.search) {
            handleQuery()
        } else {
            searchSales(page)
        }
    }, [])

    const handleQuery = async () => {
        let patient = null
        let unit = null
        let statusObj = {
            label: "Todos",
            value: null,
        }

        let search = location.search
        const params = new URLSearchParams(search)
        const unit_id = params.get("unit_id")
        const patient_id = params.get("patient_id")
        const start_created_at = params.get("start_created_at")
        const end_created_at = params.get("end_created_at")
        const status = params.get("status")
        const page = params.get("page") ? parseFloat(params.get("page")) : 1

        if (unit_id) {
            unit = await findUnit(unit_id)
            if (unit) {
                inputRefUnit.current.state.value = {
                    label: unit?.name,
                    value: unit?.id,
                }
            }
        }
        if (patient_id) {
            patient = await findPatient(patient_id)
            if (patient) {
                inputPatient.current.select.state.value = {
                    label: patient?.name,
                    value: patient?.id,
                }
            }
        }
        if (status) {
            for (let i = 0; i < statusOrders.length; i++) {
                let use = statusOrders[i]
                if (use?.value == status) {
                    statusObj = { label: use?.label, value: use?.value }
                    break
                }
            }
        }

        inputStartCreatedAt.current.value = start_created_at
        inputEndCreatedAt.current.value = end_created_at
        inputStatus.current.state.value = statusObj

        searchSales(page)
    }

    const cancelInvoicesRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja cancelar as notas fiscais deste Pedido de Venda?",
                type: "warning",
                onConfirmAction: () => {
                    confirmCancelInvoicesRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmCancelInvoicesRecord = async id => {
        try {
            await cancelSalesInvoiceDoc(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Notas Fiscais Canceladas com Sucesso!",
                    type: "success",
                })
            )

            searchSales(SalesDocsList.page)
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "sales_invoice_erp_synchronized":
                        textError =
                            "As notas fiscais desse pedido já foram sincronizadas com o SAP. Solicite ao BackOffice o cancelamento."
                        break
                    case "sales_invoice_prof_transf_paid":
                        textError =
                            "Os repasses médicos para estas notas já foram realizados. Cancele os repasses para cancelar as notas."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }

    const cancelRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja cancelar este Pedido de Venda?",
                type: "warning",
                onConfirmAction: () => {
                    confirmCancelRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmCancelRecord = async id => {
        console.log("confirmCancelRecord id", id)
        try {
            await cancelSalesDoc(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Pedido de Venda Cancelado com Sucesso!",
                    type: "success",
                })
            )

            await searchSales(SalesDocsList.page)
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "sales_order_paid":
                        textError =
                            "Pedido de Venda Pago. Cancele o recebimento de pagamento."
                        break
                    case "sales_order_invoice_issued":
                        textError =
                            "As notas fiscais desse pedido já foram emitidas. Cancele as Notas."
                        break
                    case "sales_order_canceled":
                        textError = "Este pedido já está cancelado."
                        break
                    case "sales_order_active_payments":
                        textError =
                            "Existem pagamentos ativos para este pedido. Cancele primeiro os pagamentos."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }

    const genInvoiceRecord = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Confirma a emissão de notas fiscais para este pedido?",
                type: "warning",
                onConfirmAction: () => {
                    confirmGenInvoiceRecord(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmGenInvoiceRecord = async id => {
        console.log("confirmGenInvoiceRecord id", id)
        try {
            await genInvoice(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text:
                        "Documentos gerados com sucesso! Enviando para a Emissão Fiscal!",
                    type: "success",
                })
            )

            searchSales(SalesDocsList.page)
        } catch (e) {
            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            }

            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "patient_register_incomplete":
                        textError =
                            (e.response.data.error_detail
                                ? e.response.data.error_detail
                                : "") +
                            " - O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, sexo, data de nascimento, dados de contato e endereço. Dados prenciam ser válidos."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }

    const onTableChange = (a, b, c) => {
        if (a == "pagination") {
            searchSales(b.page)
        }
    }

    const searchSales = async (page = 1) => {
        let searchObj = {}
        let stateObj = null

        let patient_id = inputPatient.current.select?.state?.value?.value
        let start_created_at = inputStartCreatedAt.current.value
        let end_created_at = inputEndCreatedAt.current.value
        let status = inputStatus.current?.state?.value?.value

        searchObj.expand_patient = true
        searchObj.expand_user = true
        searchObj.unit_id = inputRefUnit.current?.state?.value?.value

        if (patient_id) searchObj.patient_id = patient_id
        if (start_created_at) searchObj.start_created_at = start_created_at
        if (end_created_at) searchObj.end_created_at = end_created_at
        if (status) searchObj.status = status

        stateObj = {
            unit_id: inputRefUnit.current?.state?.value
                ? inputRefUnit.current?.state?.value
                : null,
            patient_id: inputPatient.current.select?.state?.value
                ? inputPatient.current.select?.state?.value
                : null,
            start_created_at: inputStartCreatedAt.current.value
                ? inputStartCreatedAt.current.value
                : null,
            end_created_at: inputEndCreatedAt.current.value
                ? inputEndCreatedAt.current.value
                : null,
            status: inputStatus.current?.state?.value
                ? inputStatus.current?.state?.value
                : null,
            page: page,
        }

        const query = `unit_id=${inputRefUnit.current?.state?.value?.value}${
            patient_id ? "&patient_id=" + patient_id : ""
        }${start_created_at ? "&start_created_at=" + start_created_at : ""}${
            end_created_at ? "&end_created_at=" + end_created_at : ""
        }${status ? "&status=" + status : ""}${
            start_created_at ? "&start_created_at=" + start_created_at : ""
        }${end_created_at ? "&end_created_at=" + end_created_at : ""}${
            page ? "&page=" + page : ""
        }`

        history.push({
            pathname: "/pedidos",
            search: query,
            state: stateObj,
        })

        await search(searchObj, page)
    }

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"  },
                    1,
                    20
                )

                let patients_values = []

                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)

    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: `#${element.id} ` + element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])
    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-4 mb-4">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Pedidos"
                        breadcrumbItem="Lista de Pedidos"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            SalesDocsList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={SalesDocsList.data || []}
                                                columns={DocsListColumns(
                                                    cancelRecord,
                                                    setModalPaymentData,
                                                    genInvoiceRecord,
                                                    cancelInvoicesRecord
                                                )}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="">
                                                            <Col sm="12">
                                                                <div className="text-sm-right ">
                                                                    <Link
                                                                        className="btn btn-primary"
                                                                        to={{
                                                                            pathname:
                                                                                "/pedidos/adicionar",
                                                                            state: {
                                                                                previousPath:
                                                                                    location?.pathname +
                                                                                    location.search,
                                                                            },
                                                                        }}
                                                                    >
                                                                        Novo
                                                                        Pedido
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-1 mb-2">
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Filtrar
                                                                    Unidade
                                                                </Label>
                                                                <Select
                                                                    name="unit_id"
                                                                    defaultValue={{
                                                                        label:
                                                                            `#${Scope.unit.id} ` +
                                                                            Scope.unit.name?.substr(
                                                                                0,
                                                                                40
                                                                            ),
                                                                        value:
                                                                            Scope
                                                                                .unit
                                                                                .id,
                                                                    }}
                                                                    ref={
                                                                        inputRefUnit
                                                                    }
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="3"
                                                            >
                                                                <Label>
                                                                    Filtrar
                                                                    Paciente
                                                                </Label>
                                                                <AsyncSelect
                                                                    name="patient_id"
                                                                    placeholder="Selecione o Paciente"
                                                                    ref={
                                                                        inputPatient
                                                                    }
                                                                    cacheOptions
                                                                    isClearable
                                                                    defaultOptions
                                                                    loadOptions={
                                                                        debounceOptionsPatient
                                                                    }
                                                                    noOptionsMessage={() =>
                                                                        "Sem Resultados. Digite para Buscar"
                                                                    }
                                                                />
                                                            </Col>

                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Criado a
                                                                    partir de{" "}
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputStartCreatedAt
                                                                    }
                                                                    defaultValue={
                                                                        todayOnlyDate
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Criado até
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    innerRef={
                                                                        inputEndCreatedAt
                                                                    }
                                                                    defaultValue={
                                                                        todayOnlyDate
                                                                    }
                                                                ></Input>
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="2"
                                                            >
                                                                <Label>
                                                                    Status
                                                                </Label>
                                                                <Select
                                                                    ref={
                                                                        inputStatus
                                                                    }
                                                                    defaultValue={{
                                                                        label:
                                                                            "Todos",
                                                                        value: null,
                                                                    }}
                                                                    options={
                                                                        statusOrders
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                sm="12"
                                                                md="12"
                                                                lg="12"
                                                                xl="1"
                                                                className="mt-1"
                                                            >
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "100%",
                                                                    }}
                                                                    type="button"
                                                                    color="primary"
                                                                    className="mt-4"
                                                                    onClick={() =>
                                                                        searchSales()
                                                                    }
                                                                >
                                                                    Buscar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "thead-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={
                                                                            onTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalPayment
                    show={modalPaymentData.show}
                    data={modalPaymentData.data}
                    onClose={operations => {
                        setModalPaymentData({ show: false, data: null })
                        if (
                            operations &&
                            (operations.create || operations.cancel)
                        )
                            searchSales(SalesDocsList.page)
                    }}
                ></ModalPayment>
            </div>
        </React.Fragment>
    )
}

export default SalesOrders
