import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { cancelPayment as cancelInvoicePayment } from "services/vitta-core/professional-transfer-invoice-payments"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
 import PROF_TRANSFER_INVOICE_STATUS from "utils/consts/prof-transfer-invoice-status"

const CancelPaymentButton = ({ id, isVisible,statusNote ,onCancelSuccess }) => {
    const dispatch = useDispatch()

    if (isVisible === false) return <></>

    const CancelInvoicePayment = async id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja cancelar o pagamento desta Nota de Repasse?",
                type: "warning",
                onConfirmAction: () => {
                    confirmCancelInvoicePayment(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmCancelInvoicePayment = async id => {
        try {
  
            if (!id) return

            await cancelInvoicePayment(id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Pagamento Cancelado com Sucesso!",
                    type: "success",
                })
            )

            onCancelSuccess()
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "prof_transfer_invoice_payment_not_found":
                        textError = "Pagamento não encontrado."
                        break
                    case "prof_transfer_invoice_not_paid":
                        textError = "Não existe pagamento para a nota."
                        break
                    case "prof_transfer_invoice_payment_has_erp_sync":
                        textError =
                            "Este Pagamento já foi enviado para o ERP e não pode mais ser cancelado."
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            console.log(e)
        }
    }

    return (
        <Link
            to="#"
            className="btn btn-danger"
            onClick={() => {
                CancelInvoicePayment(id)
            }}
        >
          {statusNote == PROF_TRANSFER_INVOICE_STATUS?.OPEN ?"Canc. Pagamento Parcial" : "Cancelar Pagamento" }  
        </Link>
    )
}

export default CancelPaymentButton
