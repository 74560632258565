import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import ModalGenInvoices from "./ModalGenInvoices"
import { Button, Card, CardBody, Col, Container, Row,Input,Label } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"

import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"

import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import {
  search as SalesOrderServiceSearch,
  genInvoice,
  genInvoicesOrders
} from "services/vitta-core/sales-order"

import SALES_ORDER_STATUS from "../../../utils/consts/sales-orders-status"
import debounce from "debounce-promise";

const today = new Date().toLocaleDateString('pt-BR');
const todayOnlyDate = today.substr(6,4) + "-" + today.substr(3,2) + "-" + today.substr(0,2)

const SalesInvoiceEmission = () => {

  const Scope = useSelector(state => state.scope);
  const User = useSelector(state => state.auth.access.user);

  const [salesOrderDocsList, setSalesOrderDocsList] = useState({
    page: 1,
    sizePerPage: 20,
    totalSize: 20,
    custom: true,
    data: [],
  })

  const [ canGen, setCanGen ] = useState(false);
  const [ searchLoading, setSearchLoading ] = useState(false);
  const [ genLoading, setGenLoading ] = useState(false);
  const [controlModal, setControlModal] = useState(false);
  const inputRefPatient = useRef();
  const inputRefUnit = useRef();
  const inputRefDocCreatedAt = useRef();

  const dispatch = useDispatch()

  const search = async (filter, page = 1) => {

    setSearchLoading(true);

    
    filter.status = SALES_ORDER_STATUS.PAID;
    filter.expand_patient = true;
    filter.expand_items = true;

    let sizePerPage = 20;
    try {
        let data = await SalesOrderServiceSearch(
            filter,
            page,
            sizePerPage
        )
         
        setSalesOrderDocsList({
            trySearch: true,
            page: page,
            sizePerPage: sizePerPage,
            totalSize: data.total_pages*sizePerPage,
            custom: true,
            data: data.data,
        })

    } catch (e) {
        console.log("error", e)
    }

    setSearchLoading(false);
  }

  const onTableChange = (a,b,c) => {
   
    if(a == "pagination")
    {
      searchSalesOrders(b.page)
    }
  }

  const genInvoiceOrders = async id => 
  {
    let unit_id = inputRefUnit.current?.state?.value?.value;
    let created_at = inputRefDocCreatedAt.current.value;

    if(!created_at)
    {
      return dispatch(
        showSweetAlertDialog({
            title: "Ops... ",
            text: "Informe o Data dos documentos no filtro.",
            type: "error",
            showConfirm: true
        })
      )
    }

    if(!unit_id)
    {
      return dispatch(
        showSweetAlertDialog({
            title: "Ops... ",
            text: "Informe a unidade no filtro.",
            type: "error",
            showConfirm: true
        })
      )
    }

    dispatch(
        showSweetAlertDialog({
            title: "Atenção ",
            text: "Tem certeza que deseja realizar a emissão de notas fiscais de todos os pedidos pagos encontrados pela busca?",
            type: "warning",
            onConfirmAction: () => {
              confirmGenInvoiceOrders()
            },
            showCancel: true,
            showConfirm: true,
            cancelBtnText: "Não",
            confirmBtnText: "Sim",
        })
    )
  }

  const confirmGenInvoiceOrders = async() =>
  {
    
    setControlModal(true);
    
  
    let patient_id = inputRefPatient.current.select?.state?.value?.value;
    let unit_id = inputRefUnit.current?.state?.value?.value;
    let created_at = inputRefDocCreatedAt.current.value;
  
    try
    {
        let filter = {
            unit_id: unit_id,
            patient_id: patient_id,
            created_at: created_at
        };
        
        await genInvoicesOrders(filter);

        dispatch(
            showToast({
                title: "Sucesso",
                text: "Notas Fiscais Geradas com Sucesso! Enviadas para a Emissão Fiscal.",
                type: "success",
            })
        )
        setControlModal(false);
        searchSalesOrders();
    }
    catch(e)
    {
        console.log(e);
        let title = "Ops... "
        let textError = "Houve um erro ao processar sua solicitação, tente novamente mais tarde...";

        if (e?.response?.status == 403) {
          textError =
              "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
        }

        if (e?.response?.data) {
          switch (e.response.data.error_code) {
            case "patient_register_incomplete":
                textError = (e.response.data.error_detail ? e.response.data.error_detail : "") + 
                    " - O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, sexo, data de nascimento, dados de contato e endereço. Dados prenciam ser válidos."
                break
            case "patient_not_active":
                textError = (e.response.data.error_detail ? e.response.data.error_detail : "") + 
                    " - O cadastro do Paciente está inativo."
                break
          }
        }
        setControlModal(false);
       return dispatch(
            showSweetAlertDialog({
                title: title,
                text: textError,
                type: "error",
                showConfirm: true
            })
        )
    }

  }

  const searchSalesOrders = (page = 1) => 
  {
    let searchObj = { };
    let patient_id = inputRefPatient.current.select?.state?.value?.value;
    let unit_id = inputRefUnit.current?.state?.value?.value;
    let created_at = inputRefDocCreatedAt.current.value;

    setCanGen(true);

    if(created_at) searchObj.created_at = created_at;
    if(unit_id) searchObj.unit_id = unit_id;
    if(patient_id) searchObj.patient_id = patient_id;

    if(!created_at)
    {
      return dispatch(
        showSweetAlertDialog({
            title: "Ops... ",
            text: "Informe o Data dos documentos no filtro.",
            type: "error",
            showConfirm: true
        })
      )
    }

    if(!unit_id)
    {
      return dispatch(
        showSweetAlertDialog({
            title: "Ops... ",
            text: "Informe a unidade no filtro.",
            type: "error",
            showConfirm: true
        })
      )
    }

    search(searchObj, page)
  };

  
const genInvoiceRecord = async id => {
  dispatch(
      showSweetAlertDialog({
          title: "Atenção ",
          text: "Confirma a emissão de notas fiscais para este pedido?",
          type: "warning",
          onConfirmAction: () => {
            confirmGenInvoiceRecord(id)
          },
          showCancel: true,
          showConfirm: true,
          cancelBtnText: "Não",
          confirmBtnText: "Sim",
      })
  )
}

const confirmGenInvoiceRecord = async id => {

  try {

      await genInvoice(id)

      dispatch(
          showToast({
              title: "Sucesso",
              text: "Documento gerado com sucesso! Enviando para a Emissão Fiscal!",
              type: "success",
          })
      )
      
      searchSalesOrders(salesOrderDocsList.page)
  } catch (e) {

    let title = "Ops... "
    let textError = "Houve um erro ao processar sua solicitação, tente novamente mais tarde...";

    if (e?.response?.status == 403) {
      textError =
          "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
    }

    if (e?.response?.data) {
      switch (e.response.data.error_code) {
        case "patient_register_incomplete":
            textError = (e.response.data.error_detail ? e.response.data.error_detail : "") + 
                " - O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, sexo, data de nascimento, dados de contato e endereço. Dados prenciam ser válidos."
            break
      }
    }

      dispatch(
          showSweetAlertDialog({
              title: title,
                text: textError,
                type: "error",
                showConfirm: true
          })
      )
      console.log(e)
  }
}

  
const loadPatientInputOptions = async (inputValue) => {
  return new Promise(async (resolve, reject) => {
      try
      {
          let response = await patientServiceSearch({term: inputValue, fields:"id,name,cpf" }, 1, 50);

          let patients_values = []

          response.data.forEach(element => {
            patients_values.push({
                  label: `${element.id} - ` + element.name + (element.cpf ? ` <${element.cpf}>` : ''),
                  value: element.id,
              })
          })

          return resolve(patients_values);
      }
      catch(e)
      {
          console.error(e);
          return reject([]);
      }
  });
}
const debounceOptionsPatient = debounce(loadPatientInputOptions,600)
  // const loadProfessionalInputOptions = async (inputValue) => {
  //   return new Promise(async (resolve, reject) => {
  //       try
  //       {
  //           let response = await professionalServiceSearch({term: inputValue}, 1, 50);

  //           let list_values = []

  //           response.data.forEach(element => {
  //             list_values.push({
  //                   label: `${element.id} - ` + element.name,
  //                   value: element.id,
  //               })
  //           })

  //           return resolve(list_values);
  //       }
  //       catch(e)
  //       {
  //           console.error(e);
  //           return reject([]);
  //       }
  //   });
  // }
 
  // const debounceOptionsProfessional = debounce(loadProfessionalInputOptions,600)

  const unitsOptions = useMemo(() => {
    if (!User?.units) return []

    let options_list = []
    User.units.forEach(element => {
        options_list.push({ label: `#${element.id} ` + element.name?.substr(0,40), value: element.id })
    })
    return options_list
  }, [User.units])

  const renderNoDataIndication = () => {
        console.log("caiuaqq")
    if(searchLoading==true) return (
      <Row align="center" className="mt-5 mb-5">  
         <Col sm="12" className="mt-5 mb-5">
              <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
              </div> 
         </Col>
      </Row>
  )
  else
  {
      return <Row className="mt-3 mb-3">  
          <Col sm="12" >
              Não há registros que correspondam com a sua busca.
          </Col>
      </Row>
  };
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pedidos" breadcrumbItem="Emissão de NFe" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(salesOrderDocsList)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={salesOrderDocsList?.data || []}
                        columns={DocsListColumns(genInvoiceRecord)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-4">
                              <Col sm="3">
                                <Label>Filtrar Unidade</Label>
                                  <Select 
                                   name="unit_id"
                                    defaultValue={Scope.unit ? {
                                      label: `#${Scope.unit.id} ` + Scope.unit.name?.substr(0,40), value: Scope.unit.id
                                    } : null }
                                    ref={inputRefUnit} 
                                    options={ unitsOptions}
                                    isClearable
                                    onChange={() => {
                                      setCanGen(false);
                                    }}
                                  />
                              </Col>
                              <Col sm="3">
                                <Label>Filtrar Paciente</Label>
                                     <AsyncSelect
                                          name="patient_id"
                                          placeholder="Selecione o Paciente"
                                          ref={inputRefPatient}
                                          cacheOptions
                                          defaultOptions 
                                          isClearable
                                          loadOptions={debounceOptionsPatient}
                                          onChange={() => {
                                            setCanGen(false);
                                          }}
                                      />
                              </Col>
                              <Col sm="2">
                                <Label>Data Pedido</Label>
                                  <Input 
                                    type="date" 
                                    innerRef={inputRefDocCreatedAt} 
                                    defaultValue={todayOnlyDate}
                                    onChange={() => {
                                      setCanGen(false);
                                    }}
                                  >
                                  </Input>
                              </Col>
                              <Col sm="1">
                                <div className="">
                                  {searchLoading ==false ? <Button className="btn btn-primary mt-4" onClick={() => searchSalesOrders()}>
                                      Buscar
                                  </Button> : <Button className="btn btn-primary mt-4" disabled>
                                      Buscar
                                  </Button> }
                                  
                                </div>
                             
                              </Col>
                              <Col sm="2">
                                {
                                  canGen && salesOrderDocsList?.data?.length > 0 ? 
                                    <div className=" ">
                                      {controlModal ==false ?  <Button className="btn btn-primary mt-4" onClick={genInvoiceOrders} color="primary">
                                          Emitir NFs
                                      </Button> :  <Button className="btn btn-primary mt-4" color="primary" disabled>
                                          Emitir NFs
                                      </Button>}
                                     
                                    </div>
                                  : null
                                }
                              </Col>
                            </Row>
                         
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={
                                      onTableChange
                                    }
                                    noDataIndication={ renderNoDataIndication}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalGenInvoices show={controlModal}></ModalGenInvoices>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesInvoiceEmission
