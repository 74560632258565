import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
import SALES_ORDER_COMISSION_TYPES from "utils/consts/sales-orders-comission-types"
const calcValue = sales_order_item_comission => {
    let value = 0
    if (sales_order_item_comission && sales_order_item_comission.length > 0) {
        for (let i = 0; i < sales_order_item_comission.length; i++) {
            let use = sales_order_item_comission[i]
            if (
                use &&
                use.comission_type == SALES_ORDER_COMISSION_TYPES.EXECUTION
            ) {
                value += (use.comission_calc_value / 100) * use?.quantity
            }
        }
    }

    return value
}
const getPrice = sales_order_item_comission => {
    let price = 0
    if (sales_order_item_comission && sales_order_item_comission.length > 0) {
        for (let i = 0; i < sales_order_item_comission.length; i++) {
            let use = sales_order_item_comission[i]
            if (
                use &&
                use.comission_type == SALES_ORDER_COMISSION_TYPES.EXECUTION
            ) {
                price = use.comission_calc_value / 100
            }
        }
    }

    return price
}
const RequestColumns = () => {
    return [
        {
            dataField: "exec_in",
            text: "Data Execução",
            formatter: (cellContent, row) =>
                new Date(row.exec_in + " 00:00:00").toLocaleDateString("pt-BR"),
        },
        // {
        //     dataField: "id",
        //     text: "OS",
        //     formatter: (cellContent, row) => (
        //         <Link
        //             to={"/ordens-servico/" + cellContent}
        //             target={"_blank"}
        //             className="mr-3 text-primary"
        //         >
        //            # {cellContent}
        //         </Link>
        //     ),
        // },
        {
            dataField: "id",
            text: "Ped. Venda",
            formatter: (cellContent, row) => (
                <Link
                    to={"/pedidos/" + cellContent}
                    target={"_blank"}
                    className="mr-3 text-primary"
                >
                    # {cellContent}
                </Link>
            ),
        },
        {
            dataField: "patient.name",
            text: "Paciente",
        },
        {
            isDummyField: true,
            text: "Items",
            formatter: (id, row) => {
                return row.items.map(data => {
                    return (
                        <>
                            {data.item.name} ({data.quantity}) - R${" "}
                            {new Intl.NumberFormat("pt-br", {
                                style: "decimal",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(
                                getPrice(data?.sales_order_item_comission)
                            )}
                            <br />
                        </>
                    )
                })
            },
        },
        // {
        //     isDummyField: true,
        //     text: "Items",
        //     formatter: (id, row) => {

        //         return row.items.map((data) => {

        //             return <>
        //                 {data.item.name} ({data.quantity}) - R$ {new Intl.NumberFormat("pt-br", {
        //                                                                 style: "decimal",
        //                                                                 currency: "BRL",
        //                                                                 minimumFractionDigits: 2,
        //                                                                 maximumFractionDigits: 2
        //                                                             }).format(data.quantity * (data.sales_item_exec_comission.split_professional_value/100))
        //                                                         }
        //                                                         <br/>

        //             </>

        //         });

        //     },
        // },
        // {
        //     isDummyField: true,
        //     text: "Total Nota",
        //     formatter: (id, row) => {

        //         let total = 0;
        //         for(let i = 0; i < row.items.length; i++)
        //         {
        //             let useItem = row.items[i];
        //             total += useItem.quantity * useItem.sales_item_exec_comission.split_professional_value
        //         }

        //         return "R$ " + new Intl.NumberFormat("pt-br", {
        //             style: "decimal",
        //             currency: "BRL",
        //             minimumFractionDigits: 2,
        //             maximumFractionDigits: 2
        //         }).format(total/100)

        //     },
        // }
        {
            isDummyField: true,
            text: "Total Nota",
            formatter: (id, row) => {
                let total = 0
                for (let i = 0; i < row.items.length; i++) {
                    let useItem = row.items[i]
                    total += calcValue(useItem.sales_order_item_comission) * 100
                }

                return (
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(total / 100)
                )
            },
        },
    ]
}

export default RequestColumns
