import React, { useState, useEffect } from "react"
import { Row, Col, Modal, Table, CardBody, Label, Button,Input} from "reactstrap"
import debounce from "debounce-promise"
import { search } from "services/vitta-core/professional"
import AsyncSelect from "react-select/async"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog } from "store/actions"
const SelectedProfessionalExecModal = ({
    show,
    data,
    onClose,
    handleExecItem,
}) => {
    const dispatch = useDispatch()
    const [dataForm, setDataForm] = useState({
        professional_id: null,
        exec_at:null
    })
    const loadDataModal = async () => {}

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await search(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )
                let data_values = [
                    {
                        label: "Todos Profissionais",
                        value: null,
                    },
                ]

                response.data.forEach(element => {
                    data_values.push({
                        label: `#${element.id} ` + element.name?.substr(0, 40),
                        value: element.id,
                    })
                })

                return resolve(data_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const handleExecWithProfessional = () => {
        if (!dataForm?.professional_id?.value) {
            dispatch(
                showSweetAlertDialog({
                    title: "Atenção!",
                    text:
                        "É necessário selecionar o profissional que executou o serviço!",
                    type: "warning",
                    showConfirm: true,
                })
            )
            return
        }
        if (!dataForm?.exec_at || dataForm?.exec_at == '') {
            dispatch(
                showSweetAlertDialog({
                    title: "Atenção!",
                    text:
                        "É necessário informar quando o serviço foi executado!",
                    type: "warning",
                    showConfirm: true,
                })
            )
            return
        }
        let dataExec = { ...data }
        dataExec.exec_professional_id = dataForm?.professional_id?.value
        dataExec.exec_in = dataForm?.exec_at;
        handleExecItem(dataExec)
        onClose()
    }

    useEffect(() => {}, [show])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            // toggle={() => {
            //     onClose()
            // }}
            size="md"
            centered
        >
            <Row style={{ padding: "20px", paddingTop: "10px" }}>
                <Col>
                    <button
                        type="button"
                        onClick={() => {
                            onClose()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h4>Dados de Execução</h4>

                    <hr />
                    <Row className="mt-3">
                        <Col sm="12">
                            <Label>Profissional</Label>
                            <AsyncSelect
                                name="profissional_id"
                                placeholder="Selecione o profissional"
                                classNamePrefix="select2-selection"
                                defaultOptions
                                isClearable
                                cacheOptions
                                loadOptions={debounceOptionsProfessional}
                                noOptionsMessage={() =>
                                    "Sem Resultados. Digite para Buscar"
                                }
                                onChange={e => {
                                    setDataForm({
                                        ...dataForm,
                                        professional_id: e,
                                    })
                                }}
                            />
                        </Col>
                        <Col sm="12" className="mt-2">
                            <Label>Quando foi executado?</Label>
                            <Input
                                name="exec_at"
                                type="date"
                                onChange={e => {
                                    setDataForm({
                                        ...dataForm,
                                        exec_at: e?.target?.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="12">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexFlow: "wrap",
                                    gap: "5px",
                                    justifyContent: "end",
                                }}
                            >
                                <Button
                                    size="sm"
                                    color="info"
                                    type="button"
                                    onClick={() => {
                                        handleExecWithProfessional()
                                    }}
                                >
                                    Executar
                                </Button>
                                <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    type="button"
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default SelectedProfessionalExecModal
