import React, { useState, useEffect } from "react"
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap"
import { Field, Form, Formik, useField } from "formik"
import BANK_CODES_TYPES from "utils/consts/bank-codes-types"
import Select from "react-select"
import { create, update } from "services/vitta-core/professional-bank-accounts"
import { showSweetAlertDialog } from "store/layout/actions"
import { useDispatch } from "react-redux"
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="text" {...field} {...props} />

const ModalAccountBank = ({ professional_id, show, data, onClose }) => {
    const dispatch = useDispatch()
    const defaultValues = {
        bank_code: "",
        account_type: "",
        bank_branch: "",
        bank_account: "",
        account_person_type: "",
    }
    const [pageData, setPageData] = useState(defaultValues)
    const [optionsBankAccount,setOptionsBankAccount] = useState([]);
    const onSubmit = async values => {
        try {
            let dataObj = { ...values }
            if (dataObj?.account_type)
                dataObj.account_type = values?.account_type?.value
            if (dataObj?.account_person_type)
                dataObj.account_person_type = values?.account_person_type?.value
            if (dataObj?.bank_code) dataObj.bank_code = values?.bank_code?.value

            let send = {
                account_person_type: dataObj?.account_person_type,
                account_type: dataObj?.account_type,
                bank_code: dataObj?.bank_code,
                bank_account: dataObj?.bank_account,
                bank_branch: dataObj?.bank_branch,
            }

            if (!data) {
                await create(professional_id, send)
            } else {
                send.id = data?.id
                await update(professional_id, send)
            }

            onClose(true)
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.error_code == "bank_account_existent") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Este tipo de conta já existe!",
                        type: "error",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    useEffect(() => {
        
        if (data) {
            setPageData(data)
        } else {
            setPageData(defaultValues)
        }
        loadBankAccounts();

    }, [show])
    const loadBankAccounts = ()=>
    {
        let dataResult = [];
        
            for(let i=0; i<BANK_CODES_TYPES?.length; i++)
            {
                let use = BANK_CODES_TYPES[i];
                dataResult.push({label:use?.code +" - "+ use.name,value:use?.code})
            }
        
            
            setOptionsBankAccount(dataResult);
        
        
    }
    return (
        <Formik
            initialValues={pageData}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({ values, dirty, setFieldValue }) => {
                return (
                    <Modal isOpen={show} centered={false} size="sm">
                        <Form>
                            <div className="modal-header">
                                <div className="modal-title " id="myModalLabel">
                                    <h5>
                                        {data
                                            ? `Conta Bancária - #${data?.id}`
                                            : "Contas Bancárias"}
                                        <i className="fas fa-file-medical-alt text-dark fa-2x ml-3"></i>
                                    </h5>
                                </div>

                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose()
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="card-body">
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label>Tipo de Conta</Label>
                                            <Field
                                                placeholder="Selecione..."
                                                name="account_person_type"
                                                component={ReactSelectInput}
                                                options={[
                                                    {
                                                        label: "Pessoa Física",
                                                        value: 1,
                                                    },
                                                    {
                                                        label:
                                                            "Pessoa Jurídica",
                                                        value: 2,
                                                    },
                                                ]}
                                                defaultValue={
                                                    values.account_person_type
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {" "}
                                        <FormGroup>
                                            <Label>Banco</Label>
                                            <Field
                                                placeholder="Selecione..."
                                                name="bank_code"
                                                component={ReactSelectInput}
                                                isMulti={false}
                                                options={optionsBankAccount}
                                                defaultValue={values.bank_code}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label>Tipo Conta Bancária</Label>
                                            <Field
                                                placeholder="Selecione..."
                                                name="account_type"
                                                component={ReactSelectInput}
                                                isMulti={false}
                                                options={[
                                                    {
                                                        label: "Corrente",
                                                        value: 1,
                                                    },
                                                    {
                                                        label: "Poupança",
                                                        value: 2,
                                                    },
                                                ]}
                                                defaultValue={
                                                    values.account_type
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {" "}
                                        <FormGroup>
                                            <Label>Agência</Label>
                                            <Field
                                                placeholder="Digite..."
                                                name="bank_branch"
                                                component={TextInput}
                                                defaultValue={
                                                    values.bank_branch
                                                }
                                                maxLength="6"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {" "}
                                        <FormGroup>
                                            <Label>Conta</Label>
                                            <Field
                                                placeholder="Digite..."
                                                name="bank_account"
                                                component={TextInput}
                                                defaultValue={
                                                    values.bank_account
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Col sm="12" align="center">
                                    {" "}
                                    <button
                                        disabled={!dirty ? true : false}
                                        align="right"
                                        type="submit"
                                        className="btn btn-dark waves-effect"
                                        data-dismiss="modal"
                                    >
                                        <span className="text-white">
                                            {" "}
                                            {!data ? "Criar" : "Atualizar"}
                                        </span>
                                    </button>
                                </Col>
                            </div>
                        </Form>
                    </Modal>
                )
            }}
        </Formik>
    )
}

export default ModalAccountBank
