import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"
// function getExecutionMode(value) {
//     switch (value) {
//         case 1:
//             return 'Presencial';
//         case 0:
//             return 'Online';
//     }
//   }
const ExecutionComissionsColumns = showModalExeComission => [

    {
        dataField: "ID",
        text: "ID",
        formatter: (cellContent, row) => (
            <Link to="#" className="text-body font-weight-bold">
                {row?.id}
            </Link>
        ),
    },
    {
        dataField: "unit.name",
        text: "Unidade",
        formatter: (cellContent, row) => (
            <div>
                {row?.unit?.name  ? row?.unit?.name  : <span className="text-danger">Todas </span>}
            </div>
        ),
    },
    {
        dataField: "professional_exec.name",
        text: "Profissional",
        formatter: (cellContent, row) => (
            <div>
                {row?.professional_exec?.name ?  row?.professional_exec?.name : <span className="text-danger">Todos </span>} 
            </div>
        ),
    },
    {
        dataField: "item.name",
        text: "Serviço",
        formatter: (cellContent, row) => (
            <div>
                {row?.item?.name}
            </div>
        ),
    },
    // {
    //     dataField: "item.execution_mode",
    //     text: "Modo de Execução",
    //     formatter: (cellContent, row) => (
    //         <div>
    //             {row?.execution_mode!=null? getExecutionMode(row?.execution_mode)  : <span className="text-danger">Ambos </span>}
    //         </div>
    //     ),
    // },
    {
        dataField: "item.comission_fix",
        text: "Comissão Fixa (R$)",
        formatter: (cellContent, row) => (
            <div className="text-body">
               &ensp;&ensp; {(row?.comission_fix/100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </div>
        ),
    },
    {
        dataField: "item.comission_percent",
        text: "Comissão Percentual (%)",
        formatter: (cellContent, row) => (
            <div className="text-body">
             &ensp;&ensp;&ensp;&ensp; &ensp;&ensp;
             {(row?.comission_percent/10000).toLocaleString('pt-br',{style: 'percent', minimumFractionDigits: 2})}
            </div>
        ),
    },

    {
        isDummyField: true,
        text: "Ações",
        formatter: (id, row) => (
            <>
                <Link
                    to="#"
                    className="mr-3 text-primary"
                    onClick={() => {
                        showModalExeComission(row)
                    }}
                >
                    {" "}
                    <i
                        className="fas fa-edit font-size-24 mr-4"
                        id={"edittooltip"+row.id}
                    ></i>
                    <UncontrolledTooltip placement="top" target={"edittooltip"+row.id}>
                        Editar
                    </UncontrolledTooltip>
                </Link>
            </>
        ),
    },
]

export default ExecutionComissionsColumns
