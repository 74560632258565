import request from "./config/request"

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.status) query += "&status=" + filter.status
    if (filter.fiscal_status) query += "&fiscal_status=" + filter.fiscal_status
    if (filter.exec_at) query += "&exec_at=" + filter.exec_at
    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.professional_id)
        query += "&professional_id=" + filter.professional_id
    if (filter.expand_payment)
        query += "&expand_payment=" + filter.expand_payment

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices` +
            query,
        vtAuth: true,
    })
}

const previewInvoices = filter => {
    let query = "?unit_id=" + filter.unit_id
    query += "&exec_in=" + filter.exec_in
    if (filter.exec_professional_id)
        query += "&exec_professional_id=" + filter.exec_professional_id

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/preview-invoices` +
            query,
        vtAuth: true,
    })
}

const genInvoices = (date_exec, exec_professional_id, ids) => {
    let data = {
        so_ids: ids,
        date_exec: date_exec,
        exec_professional_id: exec_professional_id,
    }

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices`,
        data: data,
        vtAuth: true,
    })
}

const cancelInvoice = id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${id}/cancel`,
        data: {},
        vtAuth: true,
    })
}
const showDemonstrative = filter => {
    let query = "?professional_id=" + filter.professional_id
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.date_exec) query += "&date_exec=" + filter.date_exec

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/demonstrative` +
            query,
        vtAuth: true,
    })
}
const showTransferNote = filter => {
    let query = "?professional_id=" + filter.professional_id
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.date_exec_init)
        query += "&date_exec_init=" + filter.date_exec_init
    if (filter.date_exec_finish)
        query += "&date_exec_finish=" + filter.date_exec_finish
    if (filter.status) query += "&status=" + filter.status
    if (filter.status_fiscal) query += "&status_fiscal=" + filter.status_fiscal

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/transfer-notes` +
            query,
        vtAuth: true,
    })
}
const showTransferNoteExport = filter => {
    let query = "?unit_id=" + filter.unit_id
    if (filter?.professional_id)
        query += "&professional_id=" + filter.professional_id
    if (filter.date_exec_init)
        query += "&date_exec_init=" + filter.date_exec_init
    if (filter.date_exec_finish)
        query += "&date_exec_finish=" + filter.date_exec_finish
    if (filter.status) query += "&status=" + filter.status
    if (filter.status_fiscal) query += "&status_fiscal=" + filter.status_fiscal

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/transfer-notes-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

const showHistoric = filter => {
    let query = "?professional_id=" + filter.professional_id
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.date_exec_init)
        query += "&date_exec_init=" + filter.date_exec_init
    if (filter.date_exec_finish)
        query += "&date_exec_finish=" + filter.date_exec_finish
    if (filter.date_payment_forecast_start) {
        query +=
            "&date_payment_forecast_start=" + filter.date_payment_forecast_start
    }

    if (filter.date_payment_forecast_end) {
        query +=
            "&date_payment_forecast_end=" + filter.date_payment_forecast_end
    }

    if (filter.status_payment)
        query += "&status_payment=" + filter.status_payment

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/historic` +
            query,
        vtAuth: true,
    })
}


const showHistoricExport = filter => {
    let query = "?unit_id=" + filter.unit_id.join(",")
    if (filter?.professional_id)
        query += "&professional_id=" + filter.professional_id
    if (filter.date_exec_init)
        query += "&date_exec_init=" + filter.date_exec_init
    if (filter.date_exec_finish)
        query += "&date_exec_finish=" + filter.date_exec_finish
    if (filter.date_payment_forecast_start) {
        query +=
            "&date_payment_forecast_start=" + filter.date_payment_forecast_start
    }

    if (filter.date_payment_forecast_end) {
        query +=
            "&date_payment_forecast_end=" + filter.date_payment_forecast_end
    }
    if (filter.status_payment)
        query += "&status_payment=" + filter.status_payment

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/historic-export` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}
const showReceivable = filter => {
    let query = "?date_payment_forecast_start=" + filter.date_payment_forecast_start
   

    if (filter.date_payment_forecast_end) {
        query +=
            "&date_payment_forecast_end=" + filter.date_payment_forecast_end
    }
    if (filter?.professional_id)
    {
        query += "&professional_id=" + filter.professional_id
    }
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.sales_order_id) query += "&sales_order_id=" + filter.sales_order_id
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/receivable` +
            query,
        vtAuth: true,
    })
}
const showProfessionalReceivables = filter => {
    let query = "?date_payment_forecast_start=" + filter.date_payment_forecast_start
   

    if (filter.date_payment_forecast_end) {
        query +=
            "&date_payment_forecast_end=" + filter.date_payment_forecast_end
    }
    
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.sales_order_id) query += "&sales_order_id=" + filter.sales_order_id
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/report/professional-receivables` +
            query,
        vtAuth: true,
    })
}
const changeFiscalStatusToIssued = prof_transfer_invoice_id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${prof_transfer_invoice_id}/fiscal-status/issued`,
        vtAuth: true,
    })
}

const changeFiscalStatusToOpen = prof_transfer_invoice_id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${prof_transfer_invoice_id}/fiscal-status/open`,
        vtAuth: true,
    })
}

const generateInvoicesNfse = async data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/taxdocs/emit`,
        data: data,
        vtAuth: true,
    })
}

const searchSpecificRecord = prof_transfer_invoice_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/print/${prof_transfer_invoice_id}`,
        vtAuth: true,
    })
}

const downloadInvoiceNfsePdf = async id => {
    const response = await request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${id}/taxdocs/downloadPdf`,
        vtAuth: true,
    })

    if (!response || !response.url) {
        throw "Arquivo não encontrado."
    }
    window.open(response.url, "_blank")
}

const refreshNfseStatus = async id => {
    return request({
        method: "POST",
        data: {},
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professional-transfer-invoices/${id}/taxdocs/refreshStatus`,
        vtAuth: true,
    })
}

function downloadFileFromUrl(uri, name) {
    var link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export {
    search,
    generateInvoicesNfse,
    previewInvoices,
    genInvoices,
    cancelInvoice,
    showDemonstrative,
    changeFiscalStatusToOpen,
    changeFiscalStatusToIssued,
    searchSpecificRecord,
    showHistoric,
    showHistoricExport,
    downloadInvoiceNfsePdf,
    refreshNfseStatus,
    showTransferNote,
    showTransferNoteExport,
    showReceivable,
    showProfessionalReceivables
}
