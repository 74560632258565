import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import debounce from "debounce-promise"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"

import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"

import { search as professionalServiceSearch } from "services/vitta-core/professional"
import {
    previewInvoices,
    genInvoices,
} from "services/vitta-core/professional-transfer-invoices"

const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const GenTransferInvoices = () => {
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)

    const [salesInvoiceDocsList, setSalesInvoiceDocsList] = useState({
        custom: true,
        data: [],
    })

    const [isCheck, setIsCheck] = useState([])

    const [canGen, setCanGen] = useState(false)

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setIsCheck([...isCheck, row.id])
        } else {
            setIsCheck(isCheck.filter(item => item !== row.id))
        }
        return true // return true or dont return to approve current select action
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setIsCheck(rows.map(i => i.id))
        } else {
            setIsCheck([])
        }
    }

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        selected: isCheck,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
    }

    const inputRefProfessional = useRef()
    const inputRefUnit = useRef()
    const inputRefDocExecIn = useRef()

    const dispatch = useDispatch()

    const defaultPageControl = {
        searching: false,
    }
    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async filter => {
        if (pageControl.searching) return

        try {
            setPageControl({ ...pageControl, ...{ searching: true } })

            setSalesInvoiceDocsList({
                custom: true,
                data: [],
            })

            let data = await previewInvoices(filter)

            setSalesInvoiceDocsList({
                custom: true,
                data: data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const onTableChange = (a, b, c) => {}

    const submitTransfer = async () => {
        if (isCheck.length == 0) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Selecione as execuções para realizar o repasse.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja gerar as notas de repasse das execuções selecionadas?",
                type: "warning",
                onConfirmAction: () => {
                    confirmSubmitTransfer()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmSubmitTransfer = async () => {
        let exec_date = inputRefDocExecIn.current.value
        let exec_professional_id =
            inputRefProfessional.current.select?.state?.value?.value

        try {
            await genInvoices(exec_date, exec_professional_id, isCheck)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Repasse Realizado com Sucesso!",
                    type: "success",
                })
            )

            searchPreViewInvoices()
        } catch (e) {
            console.log(e)
            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "prof_transfer_invoice_exists":
                        textError =
                            "Esta nota de Repasse já foi gerada. Entre em contato com o suporte Técnico."
                        break
                    case "date_transfer_invalid":
                        title = "Data inválida !"
                        textError =
                            "Para gerar uma nota de repasse, é necessário que a mesma possua data de execução referente à data atual ou uma data de execução inferior a data atual."
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const searchPreViewInvoices = (page = 1) => {
        let searchObj = {}
        let exec_professional_id =
            inputRefProfessional.current.select?.state?.value?.value
        let exec_in = inputRefDocExecIn.current.value
        let unit_id = inputRefUnit.current?.state?.value?.value

        setCanGen(true)
        setIsCheck([])

        if (exec_professional_id)
            searchObj.exec_professional_id = exec_professional_id
        if (exec_in) searchObj.exec_in = exec_in
        if (unit_id) searchObj.unit_id = unit_id

        // if (!exec_professional_id) {
        //     return dispatch(
        //         showSweetAlertDialog({
        //             title: "Ops... ",
        //             text: "Informe o profissional no filtro.",
        //             type: "error",
        //             showConfirm: true,
        //         })
        //     )
        // }

        if (!exec_in) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Informe o Data de execução da OS no filtro.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        if (!unit_id) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Informe a unidade no filtro.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        search(searchObj, page)
    }

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let list_values = []

                response.data.forEach(element => {
                    list_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: `#${element.id} ` + element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Repasse Médico"
                        breadcrumbItem="Gerar Notas de Repasse"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={salesInvoiceDocsList.data || []}
                                        columns={DocsListColumns()}
                                        bootstrap4
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-4">
                                                    <Col sm="3">
                                                        <Label>Unidade</Label>
                                                        <Select
                                                            name="unit_id"
                                                            defaultValue={
                                                                Scope.unit
                                                                    ? {
                                                                          label:
                                                                              `#${Scope.unit.id} ` +
                                                                              Scope.unit.name?.substr(
                                                                                  0,
                                                                                  40
                                                                              ),
                                                                          value:
                                                                              Scope
                                                                                  .unit
                                                                                  .id,
                                                                      }
                                                                    : null
                                                            }
                                                            ref={inputRefUnit}
                                                            options={
                                                                unitsOptions
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Label>
                                                            Profissional
                                                        </Label>
                                                        <AsyncSelect
                                                            name="patient_id"
                                                            placeholder="Selecione o Profissional"
                                                            ref={
                                                                inputRefProfessional
                                                            }
                                                            cacheOptions
                                                            defaultOptions
                                                            isClearable
                                                            loadOptions={
                                                                debounceOptionsProfessional
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col sm="2">
                                                        <Label>
                                                            Data Execução Item
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            innerRef={
                                                                inputRefDocExecIn
                                                            }
                                                            defaultValue={
                                                                todayOnlyDate
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        ></Input>
                                                    </Col>
                                                    <Col sm="1">
                                                        <div className="">
                                                            <Link
                                                                className="btn btn-primary mt-4"
                                                                onClick={() =>
                                                                    searchPreViewInvoices()
                                                                }
                                                            >
                                                                Buscar
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                    <Col sm="2">
                                                        {canGen &&
                                                        isCheck.length > 0 ? (
                                                            <div className=" ">
                                                                <Link
                                                                    className="btn btn-primary mt-4"
                                                                    onClick={
                                                                        submitTransfer
                                                                    }
                                                                >
                                                                    Gerar
                                                                    Repasse
                                                                </Link>
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "thead-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                selectRow={
                                                                    selectRow
                                                                }
                                                                onTableChange={
                                                                    onTableChange
                                                                }
                                                                noDataIndication={
                                                                    renderNoDataIndication
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default GenTransferInvoices
