import PropTypes from "prop-types"
import React, { useEffect, useRef, useState, useMemo  } from "react"
import { useSelector  } from "react-redux"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"

import authUtils from "utils/auth"
import { userRoutes } from "routes/allRoutes"

const SidebarContent = props => {
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously

    const userAuth = useSelector(state => state.auth.access?.user);
    
    const ref = useRef()

    useEffect(() => {
        const pathName = props.location.pathname
    
        const initMenu = () => {
          new MetisMenu("#side-menu")
          let matchingMenuItem = null
          const ul = document.getElementById("side-menu")
          const items = ul.getElementsByTagName("a")
          for (let i = 0; i < items.length; ++i) {

            if (pathName === items[i].pathname) {
              matchingMenuItem = items[i]
              break
            }
          }
          if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
          }
        }
        initMenu()
      }, [props.location.pathname])

    useEffect(() => {
        ref.current.recalculate()
    })
    
    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
            ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }   

    function activateParentDropdown(item) {
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show")
        }
    
        if (parent) {
          parent.classList.add("mm-active")
          const parent2 = parent.parentElement
    
          if (parent2) {
            parent2.classList.add("mm-show") // ul tag
    
            const parent3 = parent2.parentElement // li tag
    
            if (parent3) {
              parent3.classList.add("mm-active") // li
              parent3.childNodes[0].classList.add("mm-active") //a
              const parent4 = parent3.parentElement // ul
              if (parent4) {
                parent4.classList.add("mm-show") // ul
                const parent5 = parent4.parentElement
                if (parent5) {
                  parent5.classList.add("mm-show") // li
                  parent5.childNodes[0].classList.add("mm-active") // a tag
                }
              }
            }
          }
          scrollElement(item);
          return false
        }
        scrollElement(item);
        return false
      }
      
    const loadRoutesMenu = (menu) => {
        let routes = userRoutes.filter(e => e.menuParent == menu);
        
        let routesAllowed = [];

        for(let i = 0; i < routes?.length; i++)
        {
            let allowed = true;
            let useRoute = routes[i];
            if(useRoute?.permissions?.length > 0)
            {
                for(let p = 0; p < useRoute.permissions?.length; p++)
                {
                    if(!authUtils.hasPermission(userAuth, useRoute.permissions[p]))
                    {
                        allowed = false;
                        break;
                    }
                }
            }

            if(allowed) routesAllowed.push(
                <li >
                    <Link to={useRoute.path} className="waves-effect">
                    {useRoute.menuIcon ? <i className={useRoute.menuIcon}></i> : null}
                        <span>
                      
                        {useRoute.menuName}
                        </span>
                     
                    </Link>
                </li>
            );
        }

        return routesAllowed;
    }

    

    //todo - evitar que calcules novamente a cada mudança de url / Usememo nao esta adiantando, pode ser por causa que nao esta usando hook o state export default withRouter(withTranslation()(SidebarContent))
    const renderGestaoMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("gestao");

        //console.log("routesAllowed", routesAllowed);

        if(routesAllowed.length == 0) return;
        
        return (<li>
                <Link to="/#" className="waves-effect">
                    <i className="fas fa-user-md"></i>
                    <span className="badge badge-pill badge-info float-right">
                        {routesAllowed.length}
                    </span>
                    <span>Gestão</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                    {routesAllowed}
                </ul>
            </li>
        )
    }, [userAuth])

    const renderAgendaMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("agenda");

        //console.log("routesAllowed", routesAllowed);

        if(routesAllowed.length == 0) return;

        return (<li>
                <Link to="/#" className="waves-effect">
                    <i className="far fa-calendar"></i>
                    <span className="badge badge-pill badge-info float-right">
                        {routesAllowed.length}
                    </span>
                    <span>Agenda</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                    {routesAllowed}
                </ul>
            </li>
        )
    }, [userAuth])

    const renderVendasMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("vendas");

        //console.log("routesAllowed", routesAllowed);

        if(routesAllowed.length == 0) return;

        return (<li>
                <Link to="/#" className="waves-effect">
                    <i className="fas fa-cart-plus"></i>
                    <span className="badge badge-pill badge-info float-right">
                        {routesAllowed.length}
                    </span>
                    <span>Vendas</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                    {routesAllowed}
                </ul>
            </li>
        )
    }, [userAuth])

    const renderReportsMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("reports");

        //console.log("routesAllowed", routesAllowed);

        if(routesAllowed.length == 0) return;

        return (<li>
                <Link to="/#" className="waves-effect">
                    <i className="fas fa-chart-bar"></i>
                    <span className="badge badge-pill badge-info float-right">
                        {routesAllowed.length}
                    </span>
                    <span>Relatórios</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                    {routesAllowed}
                </ul>
            </li>
        )
    }, [userAuth])
    
    

    const renderPatientMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("patient");

        if(routesAllowed.length == 0) return;
   
        return <>{routesAllowed} </>
    }, [userAuth])

    const renderHelpMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("help");

        if(routesAllowed.length == 0) return;
   
        return <>{routesAllowed} </>
    }, [userAuth])
    

    const renderApprovalMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("approval_monitor");

        if(routesAllowed.length == 0) return;

        return <>{routesAllowed}</>
    }, [userAuth])

    const renderOSMenu = useMemo(() => {
        let routesAllowed = loadRoutesMenu("os");

        if(routesAllowed.length == 0) return;

        return <>{routesAllowed}</>
    }, [userAuth])

    const renderProfTransferPayMenu = useMemo(() => {

        let routesAllowed = loadRoutesMenu("prof_transfer_pay");

        if(routesAllowed.length == 0) return;

        return (<li>
                <Link to="/#" className="waves-effect">
                    <i className="fas fa-exchange-alt"></i>
                    <span className="badge badge-pill badge-info float-right">
                        {routesAllowed.length}
                    </span>
                    <span>Repasse Médico</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                    {routesAllowed}
                </ul>
            </li>
        )

    }, [userAuth])

    const renderTransferNotes = useMemo(() => {
        let routesAllowed = loadRoutesMenu("transfer_notes");

        if(routesAllowed.length == 0) return;

        return <>{routesAllowed}</>
    }, [userAuth])

    const renderIntegrations = useMemo(() => {
        let routesAllowed = loadRoutesMenu("integrations");

        if(routesAllowed.length == 0) return;

        return <>{routesAllowed}</>
    }, [userAuth])




    
    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Menu </li>
                    <li>
                        <Link to={"/dashboard"} >
                         <i className="fas fa-home"></i>
                         <span>Início</span>
                            
                        </Link>
                    </li>
                    {renderGestaoMenu}
                    {renderPatientMenu}
                    {renderAgendaMenu}
                    {renderVendasMenu}
                    {renderApprovalMenu}
                    {renderOSMenu}
                    {renderProfTransferPayMenu}
                    {renderTransferNotes}
                    {renderIntegrations}
                    {renderReportsMenu}
                    {renderHelpMenu}
                  
                   
                </ul>
            </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))