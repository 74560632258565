import request from "./config/request"

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size

    if (filter.patient_id) query += "&patient_id=" + filter.patient_id
    if (filter.start_created_at)
        query += "&start_created_at=" + filter.start_created_at
    if (filter.end_created_at)
        query += "&end_created_at=" + filter.end_created_at
    if (filter.unit_id) query += "&unit_id=" + filter.unit_id
    if (filter.status) query += "&status=" + filter.status

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/assessments` +
            query,
        vtAuth: true,
    })
}

const loadDataForm = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/assessments/${id}`,
        vtAuth: true,
    })
}

const findServiceWithStages = async item_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/find-service-stage/${item_id}`,
        vtAuth: true,
    })
}
const reportOdontologicData = filter => {
    let query = `?entity_type=${filter?.entity_type}&assessment_id=${filter?.assessment_id}`
    if (filter.entity_id) {
        query += `&entity_id=${filter?.entity_id}`
    }

    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/dental-values/report` +
            query,
        vtAuth: true,
    })
}
const verifyAssessmentsByAppointment = async appointment_id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/check-evaluation-by-appointment/${appointment_id}`,
        vtAuth: true,
    })
}

const startNegotiation = async assessment_id => {
    return request({
        method: "PUT",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/start-assessment-negotiation/${assessment_id}`,
        vtAuth: true,
    })
}

const updateData = async (assessment_id, data) => {
    return request({
        method: "PUT",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/update-assessment-data/${assessment_id}`,
        data: data,
        vtAuth: true,
    })
}

export {
    search,
    loadDataForm,
    findServiceWithStages,
    reportOdontologicData,
    verifyAssessmentsByAppointment,
    startNegotiation,
    updateData,
}
